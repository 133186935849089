import '@/bootstrap-reboot.css';
import {Body} from 'lizzi/DOM/Body';
import {Router} from 'lizzi/Router';

class MyApp extends Body{
    constructor(){
        super({
            title: 'Cosmos Co-watcher'
        });

        Router.exactMatch(['/check/check'], (params) => 
            import('./check/index').then(
                app => {
                    this.setTitle('Cosmos Co-watcher');
                    this.setBody( new app.CheckView() );
                }
            )
        );

        Router.exactMatch([':sessionid'], (params) => 
            import('./co-watcher/index').then(
                app => {
                    this.setTitle('Cosmos Co-watcher');
                    this.setBody( new app.CoWatcherView(params.sessionid) );
                }
            )
        );

        //404 error
        Router.noMatch(() => 
            import('./404/index').then(
                app => {
                    this.setTitle('Cosmos Co-watcher - Page not found');
                    this.setBody( new app.Page404 );
                }
            )
        );
        
        //disable backspace page back
        window.addEventListener('keydown', (e) => {
            if (e.code === 'Backspace' && !(e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement || (e.target instanceof HTMLElement && e.target.contentEditable === 'true'))) {
                e.preventDefault();
            }
        }, true);
    }
};

const app = new MyApp;
